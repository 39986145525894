<template>
  <v-container fluid>
    <!-- Wallet Amount Display -->
    <v-row align="center" style="background-color: #ecb68a !important;">
      <v-col cols="12" style="font-size: 14px !important;" class="pb-0">
        Available balance 
        <v-icon style="font-size: 14px !important;" @click="toggleVisibility">
          {{ showAmount ? 'mdi-eye' : 'mdi-eye-off' }}
        </v-icon>
      </v-col>
      <v-col cols="2" class="pr-0">
        <v-icon>mdi-food</v-icon>
      </v-col>
      <v-col cols="10" style="font-size: 25px !important;" class="pl-0">
        <div class="wallet-amount" :class="{ 'visible': showAmount }">
          {{ showAmount ? `${parseFloat(walletAmount).toLocaleString('en-US')}pcs` : '****' }}
        </div>
      </v-col>
    </v-row>

    <!-- Additional Information or Actions -->
    <v-row justify="center">
      <v-col cols="12" md="12" lg="12" class="px-0 py-0 mb-10">
        <span style="font-size: 12px !important;">
          To Buy or Sell your bread points, go to Marketplace.
        </span>
        <!-- Transaction History -->
        <v-card class="mt-1" elevation="5">
          <v-list dense>
            <v-subheader>Transaction History</v-subheader>
            <v-list-item-group v-if="transactionHistory.length > 0">
              <v-list-item v-for="transaction in transactionHistory" :key="transaction.reference" style="border-bottom:  1px solid #ecb68a !important;">
                <!-- Display transaction details -->
                <v-list-item-content>
                  <v-list-item-title>
                    <!-- Display different color chips based on the transaction type -->
                    <v-list-item-icon>
                      <v-chip :color="getChipColor(transaction.transactionType, transaction.transactionStatus)" dark>
                        {{ transaction.transactionType === 'deposit' ? '+' : '-' }}
                        {{ `${ parseFloat(Math.abs(transaction.amount + transaction.fee)).toLocaleString('en-US')}pcs` }}
                      </v-chip>
                    </v-list-item-icon>
                    <!-- Display other transaction details -->
                    <div>
                      <span class="transaction-description">{{ transaction.description }}</span>
                      <div class="transaction-details">
                        <span class="transaction-info">Balance After: {{ parseFloat(transaction.balanceAfter).toLocaleString('en-US') }}pcs</span> ~ 
                        <span class="transaction-info">Balance Before: {{ parseFloat(transaction.balanceBefore).toLocaleString('en-US') }}pcs</span><br/>
                        <span class="transaction-info">Fee: {{ parseFloat(transaction.fee).toLocaleString('en-US') }}pcs</span> ~
                        <span class="transaction-info">Total: {{ parseFloat(transaction.fee + transaction.amount).toLocaleString('en-US') }}pcs</span><br/>
                        <span class="transaction-info">Reference: {{ transaction.transactionRef }}</span><br/>
                        <span class="transaction-info">Date: {{ new Date(transaction.createdAt) }}</span>
                      </div>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title>No transactions available.</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-btn align="center" v-if="showViewMoreButton" @click="loadMoreTransactions" class="mt-2" >View More Transactions</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showAmount: false,
      walletAmount: null,
      showViewMoreButton: false,
      transactionHistory: [
        // Add more transactions as needed
      ],
      page: 1,
      showTransactionAmount: true // New variable to control visibility of transaction amounts
    }
  },
  created() {
    this.$store.commit('loading/setLoading', true, { root: true })
    this.checkEarnings()
  },
  methods: {
    toggleVisibility() {
      this.showAmount = !this.showAmount
    },/* 
    getChipColor(type) {
      return type === 'deposit' ? 'green' : 'red'
    }, */
    getChipColor(type, status) {
      if (status === 'pending') {
        return 'burlywood'
      } else if (type === 'deposit') {
        return 'green'
      } else if (type === 'withdrawal') {
        return 'red'
      } else {
        return 'burlywood'
      }
    },
    async checkEarnings() {
      try {
        const credentials = {
          userProfile: this.$store.getters['auth/userInfo'],
          page: this.page
        }
        // Dispatch the action defined in your Vuex store module
        
        if (this.$store.getters['auth/isLoggedIn']) {
          const response = await this.$store.dispatch('wallet/checkEarnings', credentials)

          if (response && response.success) {
            if (response.success) {
              ////console.log('our transactions are ', response.transactions)
              ////console.log('our balance is ', response.balance)
              //this.transactionHistory = response.transactions
              // Use the spread syntax to merge existing transactions with new transactions
              this.transactionHistory = [...this.transactionHistory, ...response.transactions]
              this.walletAmount = response.balance
              // Check if there are more transactions to load
              this.showViewMoreButton = response.totalCount > this.transactionHistory.length
            }
          }
        } else {
          this.$router.push('/login')
        }
      } catch (error) {
        
        ////console.log(error)

      } finally {
        this.$store.commit('loading/setLoading', false, { root: true })
      } 
    },
    async loadMoreTransactions() {
      this.page += 1

      this.checkEarnings()
    }
  }
}
</script>

<style scoped>
/* Add custom styles if needed */

.wallet-amount {
  font-size: 25px;
  font-weight: bold;
}

.visible {
  display: block;
}

.transaction-description {
  font-weight: bold;
  font-size: 1.0em;
  white-space: normal;
}

.transaction-details {
  margin-top: 5px;
  font-size: 13px !important;
}

.transaction-info {
  font-size: 0.9em;
}
</style>
